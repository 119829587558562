.container {
  margin: 5px auto;
}

.imagecontainer {
  padding: 20px;
  margin: 30px auto;
  width: 20vw;
  background-color: white;
  border-radius: 8px;
  box-shadow: gray 0 0 10px;
}


.images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  border: 1px solid gray;
}

#image {
  max-width: 100%;
  height: 15vh;
  width: 30vw;
}

@media screen and (max-width: 950px) {
  .images {
  }
  .imagecontainer {
    width: 35vw;
  }
}

@media screen and (max-width: 450px) {
  .imagecontainer {
    width: 45vw;
  }
  #image {
    width: 35vw;
  }
}

@media screen and (max-width: 330px) {
  .imagecontainer {
    width: 55vw;
  }
  #image {
    width: 40vw;
  }
}
