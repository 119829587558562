.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 90vw;
}

.newscontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80vw;
  margin: 0 auto;
  margin-bottom: 10px;
}
