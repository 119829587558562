.imageframe {
  border: 1px solid #ccc;
  height: 420px;
  border-radius: 4px;
}

.imageframe img {
  width: 100%;
  height: 250px;
}

.info {
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.info h4 {
  margin-top:0;
  margin-bottom:10px;
  font-weight:bold;
  font-size:16px;
}

.info p {
  margin: auto;
  font-size:16px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  margin:10px 20px;
}

.imageContainer {
  margin: 10px auto;
  text-align: center;
  width: 300px;
}

.imageContainer img {
  max-width: 100%;
  height: 150px;
  border-radius: 8px;
}

a {
  color: #2E598E;
}

a:hover {
  color: #03a9f4;
}
