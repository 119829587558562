.footer {
  height: 200px;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color:#f0f9ff;
  background-color:#282d32;
  margin-top: auto;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  width: 80vw;
  padding-left: 20px;
}

.links {
  text-align: start;
  width: 20vw;
}

.companyInfo {
  width: 40vw;
  text-align: start;
  padding-right: 20px;
  margin-bottom: 16px;
}

.companyInfo p {
  opacity:0.6;
  margin-bottom:0;
  font-size: 15px;
}

.pageLinks {
  width: 20vw;
  text-align: start;
}


.footer ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer ul a:hover {
  opacity:0.8;
}

.footer h3 {
  padding-top: 10px;
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.social {
  text-align: center;
  width: 80vw;
  margin: 0 auto;
}

.social > a {
  font-size:20px;
  width: 32px;
  height: 32px;
  line-height:36px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  margin: 0 8px;
  color: #fff;
  opacity:0.75;
}

.social > a:hover {
  opacity:0.9;
}

.icons {
  font-size: 24px;
}

.copyright {
  text-align:center;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

.copyrightContainer {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .footer {
    height: 300px;
  }
  .topContainer {
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 0px;
  }
  .companyInfo {
    text-align: center;
    width: 60vw;
    margin: 0 auto;
    padding-right: 0px;
  }
  .footer h3 {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    height: 380px;
  }
  .topContainer {
    flex-direction: column;
    justify-content: space-around;
    padding-left: 0px;
  }
  .pageLinks, .links, .companyInfo {
    text-align: center;
    width: 60vw;
    margin: 0 auto;
    padding-right: 0px;
  }
}
