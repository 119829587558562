.info {
  text-align: center;
  margin: 10px auto;
  width: 80%;
}

.icons {
  font-size: 24px;
  color: grey;
}

.icons:hover {
  color: #0789ED;
}

.info h4 {
  margin-top:0;
  margin-bottom:10px;
  font-weight:bold;
  font-size:16px;
}

.info p {
  margin: auto;
  font-size:16px;
}

.container {
  margin: 10px auto;
  text-align: center;
  width: 250px;
}

.container img {
  max-width: 100%;
  height: 200px;
  border-radius: 8px;
}
