#aboutContainer {
  padding: 5px;
}
.teamContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80vw;
  margin: 0 auto;
  margin-bottom: 20px;
}


.lastSection {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lastSection p {
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  margin: 0 auto;
  width: 50%;
}

.button {
  background-color: #03a9f4;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  width: 200px;
  margin: 10px
}

.donate {
  background-color: white;
  border: 2px solid black;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

h1 {
  display: inline-block;
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 10px;
  padding: 10px;
}

.mission {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
}

.missionText {
  width: 30vw;
  text-align: left;
  padding: 10px;
}

.missionText h1 {
  display: inline-block;
  font-size: 36px;
  margin-bottom: 0;
  margin-top: 5px;
}

.missionText p {
  padding: 10px;
}

.imageContainer {
  max-width: 100%;
  height: auto;
}

.imageContainer img {
  width: 40vw;
  border-radius: 5px;
}

@media screen and (max-width: 750px) {
  .mission {
    display: block;
    flex-direction: none;
    justify-content: none;
    margin: 0 auto;
  }
  .missionText {
    margin: 0 auto;
    text-align: center;
    width: 85vw;
  }
  .missionText p {
    text-align: left;
    margin: 0 auto;
  }
  .imageContainer img {
    width: 80vw;
  }
}
