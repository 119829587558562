.active {
  border-bottom: 2px solid #2E598E;
}

.hamburger {
  position: absolute;
  right: 5%;
  cursor: pointer;
  border-radius: 100%;
  padding: 0.3em;
}

.hamburger:active {
  background-color: lighten(#181818, 5%);
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.navLink {
  color: #2E598E;
  text-decoration: none;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.1rem;
}


.mobileNavigation {
  display: none;
}

.navigation {
  margin-right: 20px;
}

@media (min-width: 900px) {
  .navLink {
    font-weight: bold;
  }
}

@media screen and (max-width: 900px) {
  .navigation {
    display: none;
  }
  .mobileNavigation {
    display: flex;
    align-items: center;
  }
  nav ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100vh;
    width: 50vw;
    top: 60px;
    right: 0;
    padding: 1em;
    background-color: #181818;
    opacity: 0.95;
  }
}
