.firstSection {
  display: flex;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
   url("../images/newcover.png");
  background-color: rgb(53, 59, 62);
  height: 600px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  font-family: sans-serif;
  opacity: 1.0;
}

.firstSectionContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 500px;
  width: 80vw;
  margin: 30px auto;
  color: white;
  padding: 20px;
}

.mainText {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.mainText h1 {
  display: inline-block;
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 100px;
  padding: 10px;
}

.mainText p {
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  margin: 0 auto;
  width: 50%;
}

a {
  text-decoration: none;
}

.iconsContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
  margin: 0 auto;
}

.newsItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80vw;
  margin: 0 auto;
  margin-bottom: 10px;
}

.topButton {
  background-color: #03a9f4;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  width: 200px;
  margin: 10px
}

.link {
  color: #2E598E;
}

.topButton:hover {
  background-color: #0789ED;
}

.buttons {
  width: 100%;
}

.carouselSection {
  margin: 5px auto;
  display: flex;
  justify-content: center;
}

.midSection {
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.text {
  margin: 10px;
}

.text h1 {
  margin: 10px;
  font-size: 2.75em;
  line-height: 1.26;
  padding: 10px;
}

.text p {
  width: 70vw;
  padding-bottom: 20px;
  margin: 0 auto;
  text-align: left;
}

.video {
  display: flex;
  width: 60vw;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .iconsContainer {
    width: 90vw;
  }
  .topButton {
    padding: 15px 14px;
    width: 150px;
  }
  .midSection {
    width: 95vw;
    height: 680px;
  }
  .text p {
    width: 90vw;
  }
  .video {
  }
}

@media screen and (max-width: 650px) {
  .text p {
    width: 90vw;
  }
  .midSection {
    height: 690px;
  }
  .text h1 {
    font-size: 2em;
  }
  .video {
    width: 90vw;
  }
  .firstSectionContents {
    padding: 10px;
  }
  .mainText h1 {
    font-size: 48px;
  }
  .mainText p {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .firstSection {
    background-size: contain;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/newcover-md_2x.png");
  }
}

@media screen and (max-width: 520px) {
  .midSection {
    height: 740px;
  }
}

@media screen and (max-width: 405px) {
  .midSection {
    height: 820px;
  }
}
