.logo {
  display: flex;
  justify-content: left;
  margin: 5px;
}

.logo img {
  max-width: 100%;
  height: auto;
}
