.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header li a {
  display: block;
  color: #2E598E;
  text-align: center;
  padding: 8px;
  text-decoration: none;
}

.header li {
  float: left;
  padding: 10px;
}

/* Change the link color to #111 (black) on hover */
.header li a:hover {
  color: black;
}

.header {
  display: flex;
  font-size: 20px;
  background-color: white;/*rgba(0, 0, 0, 0.1);*/
  height: 75px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  width: 90vw;
}

.header img {
  height:50px;
  margin-left: 10px;
}

.mobile {
  display: none;
}

@media screen and (max-width: 900px) {
  .navLinks {
    display: none;
  }
  .mobile {
    display: flex;
    align-items: center;
  }
}
