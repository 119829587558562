.container {
  width: 80vw;
  margin: 0 auto;
}

.story {
  width: 80vw;
  margin: 20px auto;
  border-top: 2px solid #D3D3D3;
}

.storyText {
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  width: 60vw;
}

.storyText p {
  margin: 0px;
  padding: 10px;
  text-align: left;
}

.storyImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 10px;
  height: auto;
}

.storyImgContainer img {
  width: 60vw;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .container {
    width: 95vw;
  }
  .story {
    width: 90vw;
  }
  .storyText {
    width: 90%;
  }
  .storyImgContainer {
    height: 220px;
  }
  .storyImgContainer img {
    width: 90%;
  }
}
