.container {
  text-align: left;
  width: 90vw;
}

.checkmark {
  font-size: 16px;
  margin-right: 15px;
}

.item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

.text {
  float: left;
}
