.container {
  width: 80vw;
  margin: 0 auto;
  height: 280px;
}

.buttoncontainer {
  display: flex;
  justify-content: space-around;
  margin: 10px auto;
}

.button {
  height: 50px;
  width: 45%;
  background-color: white;
  color: black;
  border: 1px solid white;
}

.button:hover {
  background-color: #F5F5F5;
}

.clicked {
  background-color: #F5F5F5;
  height: 50px;
  width: 45%;
}

.clicked:hover {
  background-color: #F5F5F5;
}

.items {
  padding: 15px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 90vw;
    height: 350px;
  }
}

@media screen and (max-width: 420px) {
  .container {
    height: 500px;
  }
}

@media screen and (max-width: 350px) {
  .container {
    height: 550px;
  }
}
@media screen and (max-width: 300px) {
  .container {
    height: 620px;
  }
}
