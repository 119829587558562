.container {
  display: flex;
  width: 80vw;
  margin: 0 auto;
  height: 100vh;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 5px;
  margin-bottom: 5px;
}

.textInput {
  width: 40vw;
  padding: 5px 5px;
  margin: 8px auto;
  box-sizing: border-box;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  border-radius: 2px;
  outline: none;
}

label {
  text-align: left;
  width: 40vw;
  margin: 0 auto;
}

.textInput:focus {
  border: 3px solid rgb(122, 86, 86);
}

.textArea {
  width: 50vw;
  height: 250px;
  padding: 5px 5px;
  margin: 8px auto;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.submitContainer {
  text-align: center;
  margin: 8px;
}

.submit {
  background-color: white;
  padding: 12px 24px;
  font-size: 16px;
  border: 2px solid rgb(102, 101, 101);
  border-radius: 4px;
  color: black;
}
.submit:hover {
  background-color: rgb(102, 101, 101);
  color: white;
}

.title {
  text-align: center;
  text-decoration: solid;
  font-weight: bold;
  margin: 10px;
}

.title h2 {
  text-decoration-line: underline;
}

@media screen and (max-width: 900px) {
  .textInput {
    width: 60vw;
  }
  label {
    width: 70vw;
  }
  .textArea {
    width: 70vw;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 700px
  }
  .textInput {
    width: 70vw;
  }
  .textArea {
    width: 70vw;
    height: 30vh;
  }
  label {
    width: 70vw;
  }
}
