.container {
  width: 200px;
  height: auto;
}

.text {
  text-align: left;
}

.icons {
  font-size: 64px;
  color: #0789ED;
}

@media screen and (max-width: 900px) {
  .container {
    width: 300px;
  }
}
